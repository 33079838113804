import { mdiPhoneClassic, mdiCalendar } from "@mdi/js";
import Icon from "@mdi/react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import {
  CONTACT_LINKS,
  FOOTER_INFO,
} from "../components/Footer/Footer.constants";

import "../styles/contact.scss";

const Contact = () => {
  return (
    <main className="contact-main">
      <h3>
        <FormattedMessage
          id="app.contact.time-table"
          defaultMessage="Opening Hours"
        />
      </h3>
      <article>
        <div className="time-table-item">
          <p>
            <FormattedMessage
              id="app.contact.weekdays"
              defaultMessage="Every day"
            />
          </p>
          <i>13:30 - 15:30 / 20:30 - 23:00</i>
        </div>
        {/* <div className="time-table-item">
          <p>
            <FormattedMessage id="app.contact.sunday" defaultMessage="Sunday" />
          </p>
          <i>12:30PM - 6:30PM</i>
        </div>
        <div className="time-table-item">
          <p>
            <FormattedMessage id="app.contact.monday" defaultMessage="Monday" />
            {" - "}
            <FormattedMessage
              id="app.contact.tuesday"
              defaultMessage="Tuesday"
            />
          </p>

          <i>
            <FormattedMessage id="app.contact.closed" defaultMessage="Closed" />
          </i>
        </div> */}
      </article>

      <h3>
        <FormattedMessage
          id="app.contact.reservation-title"
          defaultMessage="Book a table:"
        />
      </h3>
      <div className="reservation-container">
        <Link
          className="reservation-item"
          to={CONTACT_LINKS.bookATableGoogle}
          target="_blank"
        >
          <Icon path={mdiCalendar} size={1} />
          <FormattedMessage
            id="app.contact.reservation-online"
            defaultMessage="Online"
          />
        </Link>
        <Link className="reservation-item" to={`tel:${FOOTER_INFO.phone}`}>
          <Icon path={mdiPhoneClassic} size={1} />
          <FormattedMessage
            id="app.contact.reservation-call"
            defaultMessage="Call us"
          />
        </Link>
      </div>
    </main>
  );
};

export default Contact;
